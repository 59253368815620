import React from "react";
import PropTypes from "prop-types";
import "./sign_up_flow.css";
import { Provider as StyletronProvider } from "styletron-react";
import styled, { ThemeProvider } from "styled-components";
import { BaseProvider } from "baseui";
import deprecatedTheme from "../styling/deprecated_theme";
import baseuiTheme from "../../components/styling/baseui_theme";
import { Client as Styletron } from "styletron-engine-atomic";
import Card from "../components/layout/card";
import { HeadingSmall } from "baseui/typography";
import { StyledLink } from "baseui/link";
import { NewTab } from "@carbon/icons-react";
import ProgramSchoolLogoBlock from "../shared/login_and_sign_up/program_school_logo_block";

const engine = new Styletron();

const StyledCard = styled(Card)`
  max-width: 540px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({ theme }) => theme.sizing.scale900};
  padding: ${({ theme }) => theme.sizing.scale1000} ${({ theme }) => theme.sizing.scale1200};
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: ${({ theme }) => theme.typography.HeadingXSmall.lineHeight};
  font-family: ${({ theme }) => theme.typography.LabelMedium.fontFamily};

  .boldText {
    font-weight: bold;
  }
`;

const StyledLinkContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.primary500};
  align-items: center;
`;

const StyledTextContent = styled.div`
  width: 100%;
`;

const StyledHeadingSmall = styled(HeadingSmall)`
  margin-top: ${({ theme }) => theme.sizing.scale1000};
  margin-bottom: ${({ theme }) => theme.sizing.scale800};
`;
class RegistrationClosed extends React.Component {
  static propTypes = {
    programData: PropTypes.shape({
      title: PropTypes.string.isRequired,
      logo: PropTypes.string,
      schoolLogo: PropTypes.string,
      schoolName: PropTypes.string.isRequired,
      schoolShortName: PropTypes.string.isRequired,
      shortName: PropTypes.string.isRequired,
    }).isRequired,
    contactEmail: PropTypes.string.isRequired,
  };
  render() {
    return (
      <StyletronProvider value={engine}>
        <BaseProvider theme={baseuiTheme}>
          <ThemeProvider theme={{ ...deprecatedTheme, ...baseuiTheme }}>
            <StyledCard>
              <div className="brand-name text-align-center">
                <ProgramSchoolLogoBlock
                  programLogo={this.props.programData.logo}
                  programName={this.props.programData.title}
                  schoolLogo={this.props.programData.schoolLogo}
                  schoolName={this.props.programData.schoolName}
                />
              </div>
              <StyledHeadingSmall>Registration Is Closed</StyledHeadingSmall>
              <p>
                Registration for {this.props.programData.shortName} {this.props.programData.title}{" "}
                at {this.props.programData.schoolName}{" "}
                <span className="boldText">is currently closed</span>.
              </p>
              <StyledTextContent>
                If you require additional assistance, email
                <StyledLinkContainer>
                  <NewTab size={18} />
                  &nbsp;
                  <StyledLink href={`mailto:${this.props.contactEmail}`}>
                    {this.props.contactEmail}.
                  </StyledLink>
                </StyledLinkContainer>
              </StyledTextContent>
            </StyledCard>
          </ThemeProvider>
        </BaseProvider>
      </StyletronProvider>
    );
  }
}

export default RegistrationClosed;
